@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '../../colors';

// add xxl breakpoint to default bootstrap breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Sibly Spacing System

$space-xxxs: calc(0.25 * 1em);
$space-xxs: calc(0.375 * 1em);
$space-xs: calc(0.5 * 1em);
$space-sm: calc(0.75 * 1em);
$space-md: calc(1.25 * 1em);
$space-lg: calc(2 * 1em);
$space-xl: calc(3.25 * 1em);
$space-xxl: calc(5.25 * 1em);
$space-xxxl: calc(8.5 * 1em);

$text-xxxs: calc(1rem / (1.2 * 1.2));
$text-xxs: calc(1rem / (1.2 * 1.2));
$text-xs: calc(1rem / 1.2);
$text-sm: 1rem;
$text-md: calc(1rem * 1.2);
$text-lg: calc(1rem * (1.2 * 1.2));
$text-xl: calc(1rem * (1.2 * 1.2 * 1.2));
$text-xxl: calc(1rem * (1.2 * 1.2 * 1.2 * 1.2));


// bootstrap only provides $font-weight-lighter(100), $font-weight-light(300), $font-weight-normal(400), $font-weight-bold(700)
$font-weight-semi-bold: 600;

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold;
}

.caption-1 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: $font-weight-bold;
  font-size: $text-xs;
  line-height: 18px;
}

.heading-3 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: $text-sm;
  line-height: 24px;
}

.heading-4 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  font-size: 14px;
  line-height: 21px;
}

.heading-5 {
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: $font-weight-bold  !important;
  font-size: $text-xs  !important;
  line-height: 18px !important;
}

.body-large {
  font-family: 'Lato';
  font-style: normal;
  font-weight: $font-weight-normal;
  font-size: $text-sm;
  line-height: 24px;
}

.body-small {
  font-family: 'Lato';
  font-style: normal;
  font-weight: $font-weight-normal;
  //we need to sync with figma on using rem units on design system classes
  font-size: 14px;
  line-height: 22px;
}

.font--xxs {
  font-size: $text-xxs;
}

.font--xs {
  font-size: $text-xs;
}

.font--sm {
  font-size: $text-sm;
}

.font--md {
  font-size: $text-md;
}

.font--lg {
  font-size: $text-lg;
}

.font--xl {
  font-size: $text-xl;
}

.font--xxl {
  font-size: $text-xxl;
}

$font-family-base: 'Lato', sans-serif !default;

// Extend Bootstrap Theme Classes

.bg-neutral-purple-gray-5 {
  background-color: $neutral-purple-gray-5;
}

.bg-yellow {
  background-color: $yellow  !important;
}

// Top bar viewport fix
$navBarHeight: 3rem;
$viewportHeight: calc(100vh - 3rem);
$chatHeaderHeight: 54px;

$notesPanelWidth: 450px;
$bioPanelWidth: 250px;

@import "~bootstrap/scss/bootstrap";

.navbar {
  height: $navBarHeight;
  z-index: 30;
  padding: 0 16px 0 0 !important;
  align-items: stretch !important;
}

.linkContainer {
  width: 98px;
  text-align: center;
}

.logoContainer {
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
}

.link {
  font-family: 'Lato';
  font-size: 14px;
  line-height: 21px;
  color: $light-purple;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.25);
    color: white;
  }

  &.activeLink {
    background-color: $purple-60;
    color: $purple-10;
  }
}

.verticalSeparator {
  height: 15px;
  border-left: 1px solid #D8D6F4CC;
  margin-top: auto;
  margin-bottom: auto;
}

@include media-breakpoint-down(md) {
  .link {
    font-size: $text-xs;
  }
}
